<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <div>
                    <h5 class="titulo_pedidos">Usuários</h5>
                    <div class="botoes_topo" v-if="this.modo == 'visualizar'">
                        <Button @click="displayCadastrarCategoria = true" label="Cadastrar Categoria"
                            class="p-button-primary" />
                    </div>
                </div>
                <InputText class="input_text" type="text" v-model="id" />
                <div class="grid">
                    <div class="col-9">
                        <label>Nome</label>
                        <InputText class="input_text" type="text" v-model="nome" />
                    </div>
                    <div class="col-3">
                        <label>Data de Nascimento</label>
                        <InputMask class="input_text" v-model="data_nascimento" mask="99/99/9999"/>
                    </div>
                </div>
                <div class="grid">
                    <div class="col-4">
                        <label>CPF</label>
                        <InputMask class="input_text" v-model="cpf" mask="999.999.999-99" @blur="validarCPF"/>
                        <InlineMessage v-if="!state.cpfValido">CPF inválido!</InlineMessage>
                    </div>
                        
                    <div class="col-8">
                        <label>E-mail</label>
                        <InputText class="input_text" v-model="email" />
                    </div>
                </div>
                <h5 class="titulo_pedidos">Endereço</h5>
                <div class="grid">
                    <div class="col-3">
                        <label>CEP</label>
                        <InputMask class="input_text" v-model="cep" mask="99.999-999" @blur="carregaEndereco"/>
                    </div>
                </div>
                <div class="grid">
                    <div class="col-9">
                        <label>Logradouro</label>
                        <InputText class="input_text" v-model="state.endereco" type="text" />
                    </div>
                    <div class="col-3">
                        <label>Número</label>
                        <InputMask class="input_text" v-model="numero" mask="9999" />
                    </div>
                </div>
                <div class="grid">
                    <div class="col-8">
                        <label>Bairro</label>
                        <InputText class="input_text" v-model="state.bairro" type="text" name="bairro" autocomplete="new-passoword"/>
                    </div>

                </div>
                <h5 class="titulo_pedidos">Alterar Senha</h5>
                <div class="grid">
                    <div class="col-6">
                    <p><label>Senha</label></p>
                    <input  :type="mostrarSenha ? 'text' : 'password'"  v-model="senha" class="input_password" autocomplete="new-password"/>
                </div>
                <div class="col-6">
                    <p><label>Confirmação de Senha</label></p>
                    <input  :type="mostrarSenha ? 'text' : 'password'" v-model="confirma_senha" class="input_password" autocomplete="new-password"/>
                </div>
            </div>
            <label for="mostrarSenha">
                <input id="mostrarSenha" type="checkbox" v-model="mostrarSenha" />
                Mostrar senha
            </label>


                <div class="div_botoes">
                    <Button label="Salvar" class="p-button-primary btn_success" @click="salvarUsuario()" />
                    <Button label="Cancelar" class="p-button-primary btn_cancel" @click="cancelar()" />
                </div>

                <table class="p-datatable-table" style="
            overflow: scroll;
            display: block;
            overflow-x: auto;
            white-space: nowrap;
          " role="rowgroup" v-if="this.modo == 'visualizar'">
                    <thead class="p-datatable-thead" role="rowgroup">
                        <Button label="Adicionar Anúncio" class="p-button-success" @click="displayAnuncio = true"
                            v-if="this.modo == 'editar'" />
                        <tr role="row">
                            <th style="
                  text-align: start;
                  padding: 0 10px;
                  border-bottom: #000 solid;
                " v-for="(nomeColuna, index) in nomeColunas" :key="index" class="p-sortable-column" tabindex="0"
                                role="cell">
                                <div class="p-column-header-content">
                                    <span v-html="nomeColuna['nome']" class="p-column-title"></span>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="p-datatable-tbody" role="rowgroup">
                        <tr v-for="(categoria, index) in grupo.categorias" :key="index" class="" role="row">
                            <td style="
                  text-align: start;
                  padding: 3px 10px;
                  border-bottom: #00000050 1px solid;
                " v-for="(nomeColuna, index) in nomeColunas" :key="index" class="" role="cell">
                                {{ categoria[nomeColuna.value] }}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Loading v-if="loading" />
                <div v-if="!loading"></div>
                <div v-if="modo == 'editar'">
                    <Button label="Salvar" class="p-button-success" style="margin-right: 15px" @click="salvarAnuncio()" />
                    <Button label="Cancelar Edição" class="p-button-danger" @click="modoVisualizarAnuncio()" />
                </div>
            </div>
        </div>
    </div>
    <Dialog header="Cadastrar Categoria" v-model:visible="displayCadastrarCategoria">
        <div class="">
            <div class="inputGroup">
                <div class="inputContainer">
                    <label>Nome</label>
                    <InputText style="width: 100%" v-model="this.nome_categoria" />
                </div>
            </div>
            <div style="text-align: center; width: 100%; margin-top: 10px">
                <Button label="Salvar" class="p-button-success" @click="salvarCategoria()" />
                <Button label="Fechar" class="p-button-danger" @click="displayCadastrarCategoria = false" />
            </div>
        </div>
    </Dialog>
    <Toast position="top-left" />
</template>

<script>
import InputMask from 'primevue/inputmask';
import Api from '../service/api';
import Loading from '../components/Loading.vue';
import { reactive, ref } from 'vue';
import { cpf } from 'cpf-cnpj-validator'; 
export default {
    setup() {
        const state = reactive({
            imagens: [],
            bairro: "",
            cpf: '',
            cpfValido: false,
        })
        return {
            state
        }
    },
    data() {
        return {
            senha: ref(''),
            senhaValida: ref(false),
            mostrarSenha: ref(false),
            msg_fim_sessao: 'A sessão expirou. Faça Login para voltar ao Hub.',
            msg_erro: 'Erro ao se comunicar com o servidor. Verifique sua internet e tente novamente. Caso o problema persista entre em contato com o suporte.',
            empresa_id: 0,
            representante: null,
            nome: ""
        };
    },
    created() {
        this.empresa = this.$storage.getStorageSync("empresa")
        this.token = this.$storage.getStorageSync("jwt")
        this.representante_id = this.$storage.getStorageSync("user").representante_id
        this.empresa_id = this.empresa.id
        this.api = new Api();
    },
    mounted() {
        if (this.$router.currentRoute.value.params.id != undefined) {
            this.carregarUsuario()
        }
        
    },
    methods: {
        carregaEndereco(event) {
            var cep = event.target.value.replace(".", "").replace("-", "");
            this.api.carregaCep(cep).then(data => {
                console.log(data.data.logradouro);
                this.state.endereco = data.data.logradouro;
                this.state.bairro   = data.data.bairro;
            });
        },
        carregarUsuario() {
            this.api.carregarRepresentante(this.token, this.$router.currentRoute.value.params.id, this.empresa_id).then(data => {
                this.id = data.data.id
                this.nome = data.data.nome
                this.cpf = data.data.cpf
                this.email = data.data.email
                this.data_nascimento = data.data.data_nascimento
                this.endereco = data.data.endereco
            //    this.bairro = data.data.bairro
                this.numero = data.data.numero
                this.cep = data.data.cep
            }).catch((error) => {
                if (error.response.status == 401) {
                    alert(this.msg_fim_sessao);
                    this.$router.push('/login');
                } else {
                    alert(this.msg_erro);
                }
            });
        },
        salvarUsuario() {
            if (this.$router.currentRoute.value.params.id != undefined) {
                this.api.atualizarRepresentante({ usuario_id: this.id, token: this.token, empresa_id: this.empresa_id, nome: this.nome, email: this.email, senha: this.senha, cpf: this.cpf, data_nascimento: this.data_nascimento, endereco: this.endereco, bairro: this.bairro, numero: this.numero, cep: this.cep }).then(data => {
                    if (data.data.verifica_erro == false) {
                        this.$router.push({ name: 'usuarios' })
                    } else {
                        alert(this.msg_erro);
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        alert(this.msg_fim_sessao);
                        this.$router.push('/login');
                    } else {
                        alert(this.msg_erro);
                    }
                });
            } else {
                this.api.salvarRepresentante({ token: this.token, empresa_id: this.empresa_id, nome: this.nome, email: this.email, senha: this.senha, cpf: this.cpf, data_nascimento: this.data_nascimento, endereco: this.endereco, bairro: this.bairro, numero: this.numero, cep: this.cep }).then(data => {
                    if (data.data.verifica_erro) {
                        this.$router.push({ name: 'usuarios' })
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        alert(this.msg_fim_sessao);
                        this.$router.push('/login');
                    } else {
                        alert(this.msg_erro);
                    }
                });
            }
        },
        salvarUsuarioDois() {
            this.api.atualizarUsuario(this.token, this.representante_id, this.nome, this.email, this.password).then(data => {
                console.log(data)
            }).catch((error) => {
                if (error.response.status == 401) {
                    alert(this.msg_fim_sessao);
                    this.$router.push('/login');
                } else {
                    alert(this.msg_erro);
                }
            });
        },
        validarCPF() {
            this.state.cpfValido = cpf.isValid(this.cpf);
        },
    

        cancelar() {
            this.$router.push('/usuarios');
        }
    },
    components: { Loading, InputMask }
}
</script>
<style scoped lang="scss">
@import "../assets/demo/badges.scss";
</style>
<style scoped>
.div_botoes {
    margin-top: 15px;
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
}

.btn_success {
    width: 15rem;
}

.btn_cancel {
    background-color: #9d9d9d;
    border: #9d9d9d solid 1px;
    margin-left: 10px;
    width: 15rem;
}

.btn_cancel:hover {
    background-color: #9d9d9d;
    border: #9d9d9d solid 1px;
}

.input_text {
    width: 100%;
}

label {
    font-size: 11pt;
    font-weight: 700;
}

.input_password{
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    color: #495057;
    background: #ffffff;
    padding: 0.75rem 0.75rem;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    appearance: none;
    border-radius: 6px;
    width:100%;
}
</style>